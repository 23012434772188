import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/shared/card"
import SignupContent from "../components/signup/signup-content"

class SignUpShipper extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Signup" />
        <div className="container mx-auto justify-center p-5">
          <Card className="max-w-full bg-white rounded shadow-lg">
            <div className="px-8 py-6">
              <SignupContent></SignupContent>
            </div>
          </Card>
        </div>
      </Layout>
    )
  }
}
export default SignUpShipper
