import React, { Component } from "react"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import HubspotService from "../../services/hubspot-service"

class SignupContent extends Component {
  hbService = null
  working = false
  success = null
  state = {
    email: "",
    firstname: "",
    lastname: "",
    company: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    industry: "",
  }
  baseState = {}
  constructor() {
    super()
    this.hbService = new HubspotService()
    this.baseState = this.state
  }
  resetForm = event => {
    this.setState(this.baseState)
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    this.working = true
    this.forceUpdate()
    let contact = this.getContact(this.state)
    this.createContact(contact)
  }
  createContact(contact, company) {
    this.hbService.SubmitForm(contact).then(
      res => {
        this.working = false
        this.success = true
        this.forceUpdate()
      },
      err => {
        this.working = false
        this.success = false
        this.forceUpdate()
      }
    )
  }
  getContact(data) {
    if (!data) return null
    return Object.keys(data).map((key, i) => {
      return {
        name: key,
        value: data[key],
      }
    })
  }
  render() {
    return (
      <div>
        <div className="text-gray-900 text-2xl mb-4 font-semibold">
          Sign up to ship with Archerhub
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="text-md text-gray-700 font-semibold"
            >
              Email <span className="text-red-700">*</span>
            </label>
            <input
              className="appearance-none bg-gray-200 border-gray-300 w-full py-2 px-3 placeholder-gray-700 focus:outline-none rounded mt-2"
              id="email"
              name="email"
              type="email"
              placeholder="jane@example.com"
              value={this.state.email}
              onChange={this.handleInputChange}
              required
            ></input>
          </div>
          <div className="mb-4">
            <label
              htmlFor="firstname"
              className="text-md text-gray-700 font-semibold"
            >
              First Name
            </label>
            <input
              className="appearance-none bg-gray-200  border-gray-300 w-full py-2 px-3 placeholder-gray-700 focus:outline-none rounded mt-2"
              id="firstname"
              name="firstname"
              type="text"
              placeholder="Jane"
              value={this.state.firstname}
              onChange={this.handleInputChange}
            ></input>
          </div>
          <div className="mb-4">
            <label
              htmlFor="lastname"
              className="text-md text-gray-700 font-semibold"
            >
              Last Name
            </label>
            <input
              className="appearance-none bg-gray-200  border-gray-300 w-full py-2 px-3 placeholder-gray-700 focus:outline-none rounded mt-2"
              id="lastname"
              name="lastname"
              type="text"
              placeholder="Doe"
              value={this.state.lastname}
              onChange={this.handleInputChange}
            ></input>
          </div>
          <div className="mb-4">
            <label
              htmlFor="company"
              className="text-md text-gray-700 font-semibold"
            >
              Company Name <span className="text-red-700">*</span>
            </label>
            <input
              className="appearance-none bg-gray-200  border-gray-300 w-full py-2 px-3 placeholder-gray-700 focus:outline-none rounded mt-2"
              id="company"
              name="company"
              type="text"
              placeholder="Company"
              value={this.state.company}
              onChange={this.handleInputChange}
              required
            ></input>
          </div>
          <div className="mb-4">
            <label
              htmlFor="phone"
              className="text-md text-gray-700 font-semibold"
            >
              Phone Number <span className="text-red-700">*</span>
            </label>
            <input
              className="appearance-none bg-gray-200  border-gray-300 w-full py-2 px-3 placeholder-gray-700 focus:outline-none rounded mt-2"
              id="phone"
              name="phone"
              type="text"
              placeholder="###-###-####"
              value={this.state.phone}
              onChange={this.handleInputChange}
              required
            ></input>
          </div>
          <div className="mb-4">
            <label
              htmlFor="address"
              className="text-md text-gray-700 font-semibold"
            >
              Street Address
            </label>
            <input
              className="appearance-none bg-gray-200  border-gray-300 w-full py-2 px-3 placeholder-gray-700 focus:outline-none rounded mt-2"
              id="address"
              name="address"
              type="text"
              placeholder="Street"
              value={this.state.address}
              onChange={this.handleInputChange}
            ></input>
          </div>
          <div className="mb-4">
            <label
              htmlFor="city"
              className="text-md text-gray-700 font-semibold"
            >
              City <span className="text-red-700">*</span>
            </label>
            <input
              className="appearance-none bg-gray-200  border-gray-300 w-full py-2 px-3 placeholder-gray-700 focus:outline-none rounded mt-2"
              id="city"
              name="city"
              type="text"
              placeholder="City"
              value={this.state.city}
              onChange={this.handleInputChange}
              required
            ></input>
          </div>
          <div className="mb-4">
            <label
              htmlFor="state"
              className="text-md text-gray-700 font-semibold"
            >
              State/Region <span className="text-red-700">*</span>
            </label>
            <input
              className="appearance-none bg-gray-200  border-gray-300 w-full py-2 px-3 placeholder-gray-700 focus:outline-none rounded mt-2"
              id="state"
              name="state"
              type="text"
              placeholder="State/Region"
              value={this.state.state}
              onChange={this.handleInputChange}
              required
            ></input>
          </div>
          <div className="mb-4">
            <label
              htmlFor="industry"
              className="text-md text-gray-700 font-semibold"
            >
              Industry
            </label>
            <input
              className="appearance-none bg-gray-200  border-gray-300 w-full py-2 px-3 placeholder-gray-700 focus:outline-none rounded mt-2"
              id="industry"
              name="industry"
              type="text"
              placeholder="Industry"
              value={this.state.industry}
              onChange={this.handleInputChange}
            ></input>
          </div>
          <div className="flex justify-end">
            <button
              className="bg-transparent active:bg-gray-700 hover:text-gray-600 focus:outline-none font-semibold text-gray-800 text-lg py-2 px-4"
              type="button"
              onClick={this.resetForm}
            >
              Reset
            </button>
            <button
              className="bg-black hover:bg-gray-800 active:bg-gray-900 hover:text-gray-100 focus:outline-none text-gray-100 font-bold text-lg py-2 px-4 ml-2 rounded"
              disabled={this.working}
              type="submit"
            >
              Sign Up
              {this.working === true ? (
                <div className="ml-2">
                  <Loader type="Bars" color="#3182ce" height={30} width={30} />
                </div>
              ) : (
                <></>
              )}
            </button>
          </div>
          {this.success === true ? (
            <div
              className="text-md mt-3 bg-green-200 border-t-4 border-green-500 rounded-b text-green-900 px-3 py-1 shadow-md"
              role="alert"
            >
              Thank you for your interest in Archerhub. Looking forward to
              working with you.
            </div>
          ) : (
            <></>
          )}
          {this.success === false ? (
            <div
              className="text-xs mt-3 bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-3 py-1 shadow-md"
              role="alert"
            >
              There was a problem submitting your information. Please try again
              later or contact support.
            </div>
          ) : (
            <></>
          )}
        </form>
      </div>
    )
  }
}
export default SignupContent
